@font-face {
  font-family: "CentraNo1-Book";
  src: url("/fonts/CentraNo1-Book.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "CentraNo1-BookItalic";
  src: url("/fonts/CentraNo1-BookItalic.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "CentraNo1-Light";
  src: url("/fonts/CentraNo1-Light.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "CentraNo1-LightItalic";
  src: url("/fonts/CentraNo1-LightItalic.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "CentraNo1-Medium";
  src: url("/fonts/CentraNo1-Medium.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}